import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
  pageStyle,
  logoStyle,
  titleStyle,
  subtitleStyle,
  cardTextStyle,
  cardGroupStyle,
  socialCardStyle,
} from './App.style';

import logo from './icons/logo.svg';
import vk from './icons/vk.svg';
import instagram from './icons/instagram.svg';
import telegram from './icons/telegram.svg';
import whatsapp from './icons/whatsapp.svg';

type socialLogo = typeof instagram | typeof telegram | typeof whatsapp;

function SocialCard(props: { logotype: socialLogo; name: string; description: string; url: string }): JSX.Element {
  const { logotype, name, description, url } = props;

  const redirect = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card variant="outlined" sx={{ marginTop: '16px' }}>
      <CardActionArea onClick={redirect}>
        <CardContent>
          <Grid container style={socialCardStyle}>
            <Grid item xs={3} sm={2}>
              <img src={logotype} alt={name} />
            </Grid>
            <Grid item xs={9} sm={10}>
              <Typography sx={cardTextStyle}>{description}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function App() {
  return (
    <Box sx={pageStyle}>
      <img src={logo} alt="logo" style={logoStyle as object} />
      <Typography sx={subtitleStyle}>Мастерская аромамира!</Typography>
      <Typography sx={titleStyle}>Вау! Как мило!</Typography>
      <Box sx={cardGroupStyle}>
        <SocialCard
          logotype={vk}
          name="vk"
          description="Подписывайтесь на нас во ВКонтакте"
          url="https://vk.com/wow_kak_milo"
        />
        <SocialCard
          logotype={telegram}
          name="telegram"
          description="Следите за нами в Telegram"
          url="https://t.me/wow_kak_milo"
        />
        <SocialCard
          logotype={whatsapp}
          name="whatsapp"
          description="Оставляйте заказы в WhatsApp"
          url="https://wa.me/c/79037699988"
        />
        <SocialCard
          logotype={instagram}
          name="instagram"
          description="Смотрите наши работы в Instagram"
          url="https://instagram.com/wow_kak_milo?igshid=MzRlODBiNWFlZA=="
        />
      </Box>
    </Box>
  );
}

export default App;
