export const pageStyle = {
  textAlign: 'center',
  minHeight: '50vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(10px + 2vmin)',
};

export const logoStyle = {
  height: '15vmin',
  pointerEvents: 'none',
  margin: '10px',
};

export const titleStyle = {
  display: 'block',
  fontSize: '2em',
  marginBlockStart: '0.5em',
  marginBlockEnd: '0.67em',
  fontWeight: 'bold',
};

export const subtitleStyle = {
  display: 'block',
  marginBlockStart: '1em',
  marginBlockEnd: '0.2em',
  fontStyle: 'italic',
  fontSize: '1.3em',
};

export const cardTextStyle = {
  display: 'block',
  fontSize: '1.5rem',
  fontWeight: 400,
};

export const cardGroupStyle = {
  flexGrow: 1,
  overflow: 'hidden',
  px: 3,
  minWidth: { sm: undefined, md: 650 },
};

export const socialCardStyle = {
  alignItems: 'center',
  minHeight: 80,
};
